.c-navbar {
    padding: $spacer*1.5 0;
    background-color: #ffffff;

    .navbar-toggler svg {
        width: 24px;
    }

    .navbar-toggler {

        .st0,
        .st1 {
            transition: all 0.5s ease-in-out;
        }

        .st0 {
            fill: $primary;
        }

        &:hover,
        &:focus {
            .st0 {
                fill: $petrol;
            }
            .st1 {
                stroke: $petrol;
            }
        }

    }
}
