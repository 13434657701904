.c-marqueeText {
    overflow: hidden;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    background-color: $primary;
    display: block;

    &:hover,
    &:focus {
        background-color: $petrol;
        text-decoration: none;
    }

    .row {
        flex-wrap: nowrap;
    }

    &__item {
        display: flex;
        align-items: center;
        align-content: center;
        justify-items: center;
        justify-content: center;
        text-align: center;
    }

    &__item svg {
        width: 19px;
        margin-left: 1rem;
        margin-right: 1rem;
    }
}
