// Colors
$orange: #ee7203;
$yellow: #ffdd00;
$blue: #006878;
$blue-light: #0cb4bd;


$primary: $orange;
$secondary: $yellow;
$light: #ffffff;
$petrol: $blue;
$petrol-light: $blue-light;

$theme-colors: (
    "petrol": $petrol
);

// Typography
$font-family-sans-serif: "DINNextRoundedLTPro-Regular", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

/* Fix when multiple fonts available.
$headings-font-family: "DINNextRoundedLTPro-Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
 */

$headings-font-family: "DINNextRoundedLTPro-Regular", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$headings-color: $orange;

$font-size-base: 1.125rem;

$link-hover-color: $petrol;

// Components
$border-radius: 2rem;
$border-radius-lg: 2rem;

// Buttons
$input-btn-padding-y-lg: 1rem;
$input-btn-padding-x-lg: 1.5rem;

// Spacing
$spacer: 1rem;
$spacer-xs: $spacer * .25;
$spacer-sm: $spacer * .5;
$spacer-lg: $spacer * 1.5;
$spacer-xl: $spacer * 3;

$enable-responsive-font-sizes: true;

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 2190px,
);

// Popovers
$popover-border-radius: 1rem;
