.c-home {

    padding-bottom: 1rem;

    @include media-breakpoint-up(lg) {
        padding-bottom: 0;
    }

    &__headlineWrapper {
        padding-top: $spacer;

        @include media-breakpoint-up(lg) {
            padding-top: $spacer-xl;
        }
    }

    &__icon {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        margin-right: 2rem;

        img {
            width: 89px;
        }

        @include media-breakpoint-up(md) {
            margin-right: 4rem;
        }

        @include media-breakpoint-up(lg) {

            margin-right: 0;

            img {
                width: 185px;
            }
        }
    }

    &__mapWrapper {
        overflow: hidden;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        min-height: 400px;

        @include media-breakpoint-up(lg) {
            min-height: auto;
        }
    }

    &__map {
        position: absolute;
        left: 0;
        bottom: 0;
        background-image: url("/images/maps/screen_intro_mob_907x495.png");
        background-size: cover;
        width: 100%;
        height: 100%;

        @include media-breakpoint-up(lg) {
            background-image: url("/images/maps/screen_intro_167x914.png");
        }
    }

    &__button {
        z-index: 1;
    }


}
