.c-mainNavigation {
    .nav-item {
        @include font-size($h4-font-size);
    }

    .nav-link {
        display: grid;
        grid-template-columns: 32px 1fr;
        padding: 1rem;
        color: $primary;

        &:hover,
        &:focus {
            color: $petrol;
        }

        @include media-breakpoint-up(lg) {
            padding: 1.5rem;
        }
    }

    &__number {
        //color: $primary;
    }

    &__link--big.nav-link {
        display: block;
        margin-left: 32px;

        color: $primary;

        &:hover,
        &:focus {
            color: $petrol;
        }
    }
}
