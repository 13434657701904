@import url("https://hello.myfonts.net/count/3d3302");

@font-face {
    font-family: "DINNextRoundedLTPro-Regular";
    src: url('../fonts/DINNextRoundedLTProRegular/font.woff2') format('woff2'), url('../fonts/DINNextRoundedLTProRegular/font.woff') format('woff');
}
/* Fix multiple fonts. Doesn't work at the moment.
@font-face {
    font-family: "DINNextRoundedLTPro-Medium";
    src: url('../fonts/DINNextRoundedLTProMedium/font.woff2') format('woff2'), url('../fonts/DINNextRoundedLTProMedium/font.woff') format('woff');
}
@font-face {
    font-family: "DINNextRoundedLTPro-Regular";
    src: url('../fonts/DINNextRoundedLTProRegular/font.woff2') format('woff2'), url('../fonts/DINNextRoundedLTProRegular/font.woff') format('woff');
}
@font-face {
    font-family: "DINNextRoundedLTPro-Bold";
    src: url('../fonts/DINNextRoundedLTProBold/font.woff2') format('woff2'), url('../fonts/DINNextRoundedLTProBold/font.woff') format('woff');
}
@font-face {
    font-family: "DINNextRoundedLTPro-Light";
    src: url('../fonts/DINNextRoundedLTProLight/font.woff2') format('woff2'), url('../fonts/DINNextRoundedLTProLight/font.woff') format('woff');
}
 */

// Variables
@import 'variables';

// Vendor
@import '~bootstrap/scss/bootstrap';
@import '~animate.css';
@import '~shepherd.js/dist/css/shepherd.css';

// Elements
@import '40_elements/elements.body';
@import '40_elements/elements.links';

// Components
@import '60_components/components.navbar';
@import '60_components/components.navContent';
@import '60_components/components.landingPageMap';
@import '60_components/components.landingPage';
@import '60_components/components.mainNavigation';
@import '60_components/components.bottomNavigation';
@import '60_components/components.socialMediaNavigation';
@import '60_components/components.home';
@import '60_components/components.buttons';
@import '60_components/components.footer';
@import '60_components/components.footerLegalNavigation';
@import '60_components/components.modal';
@import '60_components/components.map';
@import '60_components/components.footerMapNavigation';
@import '60_components/components.menuBackdrop';
@import '60_components/components.cookieConsent';
@import '60_components/components.mapTutorial';
@import '60_components/components.mapTutorialBackdrop';
@import '60_components/components.mapZoom';
@import '60_components/components.mapChoice';
@import '60_components/components.popover';
@import '60_components/components.shepherd';
@import '60_components/components.marqueeText';
@import '60_components/components.factsBackdrop';
@import '60_components/components.factsTourModal';

// Utilities
@import '70_utilities/utilities.backgrounds';
