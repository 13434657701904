.c-mapTutorial {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding-top: 90.25px;
    padding-bottom: 80px;
    z-index: 1000;
    color: #ffffff;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    align-items: center;

    &.mapTutorialOpen {
        display: grid;
    }

    img {
        width: 100%;
        max-width: 100px;
        height: auto;
        margin-bottom: $spacer-xl;

        @include media-breakpoint-up(lg) {
            max-width: 150px;
            margin-bottom: 0;
        }
    }
}
