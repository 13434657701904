.u-backgroundEnergyImpulse {
    background-color: #ee7203;
    background-size: cover;
    background-position: bottom right;
    background-image: url('../../images/backgrounds/energy-impulse-1920.jpg');
    min-height: calc(100vh - 90px);
    padding-bottom: 2rem;
    display: grid;

    @media (min-width: 1921px) {
        background-image: url('../../images/backgrounds/energy-impulse-2560.jpg');
    }
}
