.c-footerLegalNavigation {
    justify-content: flex-end;

    .nav-link {
        color: #ffffff;

        &.hover,
        &:focus {
            color: $black;
        }
    }
}
