.shepherd-button {
    @extend .btn;

    @include button-variant($primary, $primary);

    &:hover,
    &:focus {
        background-color: $petrol;
        color: #ffffff;
        border-color: $petrol;
    }

    &:focus {
        @if $enable-shadows {
            @include box-shadow($btn-box-shadow, 0 0 0 $btn-focus-width rgba(mix(color-yiq($petrol), $petrol, 15%), .5));
        } @else {
            // Avoid using mixin so we can pass custom focus shadow properly
            box-shadow: 0 0 0 $btn-focus-width rgba(mix(color-yiq($petrol), $petrol, 15%), .5);
        }
    }
}

.shepherd-button:not(:disabled):hover {
    color: color-yiq(darken($petrol, 7.5%));
    @include gradient-bg(darken($petrol, 7.5%));
    border-color: darken($petrol, 10%);
}
