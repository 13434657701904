.c-footerMapNavigation {
    padding-top: $spacer;
    padding-bottom: $spacer;
    background-color: #ffffff;

    .nav-link {

        padding-right: 0.75rem;
        padding-left: 0.75rem;

        @include media-breakpoint-up(lg) {
            padding-right: 1rem;
            padding-left: 1rem;
        }

        &:hover,
        &:focus {

            svg circle {
                fill: $petrol-light;
            }

        }

        svg {
            width: 27px;

            @include media-breakpoint-up(lg) {
                width: 32px;
            }
        }

        svg circle {
            fill: $petrol;
            transition: all 0.5s ease-in-out;
        }
    }
}
