html .cc_dialog {
    padding: 1rem;
    font-size: 0.75rem;
}

html .cc_dialog.simple {
    max-width: none;
    width: 100%;
}

html .cc_dialog .cc_dialog_text {
    font-size: 0.75rem;
}

@media (min-width: 1200px) {
    html .cc_dialog.simple {
        max-width: 50%;
        width: auto;
    }
}

/*
@media (max-width: 600px) {

    html .cookie-consent-preferences-overlay {
        overflow-y: visible;
    }

    html .cookie-consent-preferences-overlay .cookie-consent-preferences-dialog {
        overflow-y: scroll;
        height: 100%;
    }
}
*/

.cc_dialog div div:last-child {
    display: flex;
}

html .cc_dialog button {
    font-size: 0.75rem;
}

.cc_dialog button,
.cookie-consent-preferences-overlay button {
    transition: all 0.3s;
}

html .cookie-consent-preferences-overlay .cookie-consent-preferences-dialog .cc_cp_container .cc_cp_head {
    display: none;
}

html .cookie-consent-preferences-overlay .cookie-consent-preferences-dialog .cc_cp_container .cc_cp_content .cc_cp_m_content .cc_cp_m_content_entry h1 {
    font-size: 16px;
}

html .cookie-consent-preferences-overlay .cookie-consent-preferences-dialog .cc_cp_container .cc_cp_content .cc_cp_m_content .cc_cp_m_content_entry p {
    font-size: 12px;
}

html .cookie-consent-preferences-overlay input[type="checkbox"].checkbox_cookie_consent+label {
    font-size: 12px;
}

html .cookie-consent-preferences-overlay .cookie-consent-preferences-dialog .cc_cp_container .cc_cp_footer .cc_cp_f_save button {
    font-size: 12px;
}

.cc_dialog .cc_dialog_headline {
    display: none;
}

.cc_dialog.light.cc_dialog button.cc_b_ok {
    background-color: $primary;
}

.cc_dialog.light.cc_dialog button.cc_b_ok:hover {
    background-color: $petrol;
    color: #ffffff;
}

html .light.cc_dialog button.cc_b_cp:hover {
    background-color: $petrol;
    color: #ffffff;
}

html .cookie-consent-preferences-overlay .cookie-consent-preferences-dialog .cc_cp_container .cc_cp_head {
    padding-top: 20px;
    padding-bottom: 20px;
}

.cookie-consent-preferences-overlay .cookie-consent-preferences-dialog .cc_cp_container .cc_cp_head .cc_cp_head_lang_selector {
    display: none !important;
}

html .cookie-consent-preferences-overlay .cookie-consent-preferences-dialog .cc_cp_container .cc_cp_footer .cc_cp_f_powered_by {
    display: none !important;
}

html .light.cookie-consent-preferences-overlay .cookie-consent-preferences-dialog .cc_cp_container .cc_cp_footer .cc_cp_f_save button {
    background-color: $primary;
}

html .light.cookie-consent-preferences-overlay .cookie-consent-preferences-dialog .cc_cp_container .cc_cp_footer .cc_cp_f_save button:hover {
    background-color: $petrol;
    color: #ffffff;
}

html .cookie-consent-preferences-overlay .cookie-consent-preferences-dialog .cc_cp_container .cc_cp_footer {
    padding: 10px;
    justify-content: center;
}

html .light.cookie-consent-preferences-overlay .cookie-consent-preferences-dialog .cc_cp_container .cc_cp_content .cc_cp_m_content a {
    color: $primary;
}

html .light.cookie-consent-preferences-overlay .cookie-consent-preferences-dialog .cc_cp_container .cc_cp_content .cc_cp_m_content a:hover,
html .light.cookie-consent-preferences-overlay .cookie-consent-preferences-dialog .cc_cp_container .cc_cp_content .cc_cp_m_content a:focus {
    color: $petrol;
    text-decoration: none;
}

html .cookie-consent-preferences-overlay input[type="checkbox"].checkbox_cookie_consent:checked + label::before {
    background: $primary;
}
