.c-modal {

    .modal-dialog {
        margin-right: 15px;
    }

    .modal-body {
        padding: 1rem;

        @include media-breakpoint-up(lg) {
            padding: 2rem;
        }
    }

    .close {

        opacity: 1;

        svg {
            width: 37px;
        }

        svg path {
            transition: fill 0.5s ease-in-out;
        }

        &:hover,
        &:focus {
            svg path {
                fill: $petrol;
            }
        }
    }

    .close:not(:disabled):not(.disabled):hover,
    .close:not(:disabled):not(.disabled):focus {
        opacity: 1;
    }

    &__image {
        width: auto;
        max-height: 134px;

        @include media-breakpoint-up(md) {
            max-height: 244px;
        }
    }

}


/* Hide Button for info modals */
.c-modal#info01,
.c-modal#info02,
.c-modal#info03 {
    .btn {
        display: none;
    }
}
