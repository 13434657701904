.c-bottomNavigation {

    &:first-child a {
        padding-left: 0;
    }

    .nav-link {
        color: $black;

        &:hover,
        &:focus {
            color: $petrol;
        }
    }
}
