.c-socialMediaNavigation {

    .nav-item:first-child a  {
        padding-left: 0;
    }

    .nav-item svg {
        height: 15px;
    }

    .nav-link {
        svg path {
            transition: all 0.5s ease-in-out;
        }

        &:hover,
        &:focus {

            svg path {
                fill: $petrol;
            }

        }
    }

}


