body {
    height: 100%;

    &.menuOpen {
        overflow: hidden;
    }

    &.pageMap {
        max-height: 100vh;
        overflow: hidden;
    }

    &.pageHome {
        overflow-x: hidden;
    }
}
