.c-mapZoom {
    position: absolute;
    right: 30px;
    bottom: 100px;
    width: 41px;
    height: calc((3*41px) + (2*1rem));
    display: flex;
    flex-flow: column;
    align-items: center;

    .btn {
        width: 41px;
        height: 41px;

        &:focus {
            background-color: $petrol;
            border-color: $petrol;
        }
    }

    &__help {
        margin-bottom: 1rem;
    }

    &__zoomIn {
        margin-bottom: 1rem;
    }
}
