.c-factsBackdrop {
    &:after {
        position: absolute;
        top: 0;
        right: 0;
        width: 0;
        height: 0;
        background: rgba(0,0,0,0.5);
        content: '';
        opacity: 0;
        transition: opacity 0.5s, width 0.1s 0.5s, height 0.1s 0.5s;
    }

    &.factsOpen {
        overflow-y: hidden;

        &:after {
            width: 100%;
            height: 100%;
            opacity: 1;
            transition: opacity 0.5s;
        }
    }
}
