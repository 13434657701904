.c-map {

    &__mapContainer {
        max-height: 100vh;
        overflow: hidden;

        &:focus {
            outline: none;
        }
    }

    #pin_01_1,
    #pin_01_2,
    #pin_01_3,
    #pin_01_4,
    #pin_02_1,
    #pin_02_2,
    #pin_03_1,
    #pin_03_2,
    #pin_03_3,
    #pin_04_1,
    #pin_04_2,
    #pin_05_1,
    #info_icon_1,
    #info_icon_2,
    #gewinner_pin {

        cursor: pointer;

        &:hover,
        &:focus {
            path:first-child {
                fill: $petrol-light;
            }
        }

        path:first-child {
            transition: fill 0.5s ease-in-out;
        }
    }

    #icon_fact_01,
    #icon_fact_02,
    #icon_fact_03,
    #icon_fact_04,
    #icon_fact_05,
    #icon_fact_06,
    #icon_fact_07,
    #icon_fact_08,
    #icon_fact_09,
    #icon_fact_10,
    #icon_fact_11,
    #icon_fact_12,
    #icon_fact_13,
    #icon_fact_14 {

        cursor: pointer;

        &:hover,
        &:focus {
            path:first-child {
                fill: #F9F1BB;
            }
        }

        path:first-child {
            transition: fill 0.5s ease-in-out;
        }
    }

    .facts-tour-shepherd-target {
        path:first-child {
            fill: #F9F1BB;
        }
    }


}
