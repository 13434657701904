.c-landingPage {

    &__header {
        padding-bottom: $spacer;
    }

    &__introTextWrapper {
        padding-top: $spacer-xl;

        @include media-breakpoint-up(lg) {
            padding-top: $spacer;
        }
    }

    &__titleWrapper {
        padding-bottom: $spacer;
    }

    &__leadWrapper {
        padding-top: $spacer;
    }

    &__main {
        padding-top: $spacer;
        padding-bottom: $spacer;
    }

    &__introImageWrapper img {
        max-width: 100%;
        height: auto;
    }

    &__navigation {
        svg {
            width: 35px;
        }

        svg path {
            transition: all 0.5s ease-in-out;

            &:hover,
            &:focus {
                fill: $petrol;
            }
        }
    }
}
