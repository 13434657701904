.c-factsTourModal {


    right: 0 !important;
    left: auto !important;
    transform: translate(0, -50%) !important;
    border-radius: 0;
    background: transparent;
    box-shadow: none;
    padding-right: 15px;
    padding-left: 15px;
    z-index: 1050;

    @include media-breakpoint-up(lg) {
        right: 15px !important;
        padding-right: 0;
        padding-left: 0;
    }

    @include media-breakpoint-up(sm) {
        max-width: 500px;
    }

    .shepherd-content {
        border: 1px solid rgba(0,0,0,.2);
        border-radius: 2rem;
        padding: 1rem;
        background: $white;

        @include media-breakpoint-up(lg) {
            padding: 2rem;
        }
    }

    .shepherd-header {
        padding: 0;
        line-height: 1.5;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 3rem;

        h2 {
            margin-bottom: 0;
        }
    }

    .shepherd-cancel-icon {
        svg {
            width: 37px;
        }

        svg path {
            transition: fill 0.5s ease-in-out;
        }

        &:hover,
        &:focus {
            svg path {
                fill: $petrol;
            }
        }
    }

    .shepherd-text {
        @include font-size($font-size-base);
        padding: 0;
        line-height: 1.5;
        color: $body-color;
        margin-bottom: 3rem;
    }

    .shepherd-footer {
        padding: 0;
        justify-content: space-between;
    }

    .shepherd-button {
        @extend .btn;
        @extend .btn-primary;
        @extend .btn-lg;
        width: 50%;
    }


}
