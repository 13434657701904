.btn {
    transition: color 0.5s ease-in-out, background-color 0.5s ease-in-out, border-color 0.5s ease-in-out, box-shadow 0.5s ease-in-out
}

.btn-light {
    color: $primary;

    &:hover,
    &:focus {
        background-color: $petrol;
        color: #ffffff;
        border-color: $petrol;
    }

    &:focus {
        @if $enable-shadows {
            @include box-shadow($btn-box-shadow, 0 0 0 $btn-focus-width rgba(mix(color-yiq($petrol), $petrol, 15%), .5));
        } @else {
            // Avoid using mixin so we can pass custom focus shadow properly
            box-shadow: 0 0 0 $btn-focus-width rgba(mix(color-yiq($petrol), $petrol, 15%), .5);
        }
    }
}

.btn-primary {

    &:hover,
    &:focus {
        background-color: $petrol;
        color: #ffffff;
        border-color: $petrol;
    }

    &:focus {
        @if $enable-shadows {
            @include box-shadow($btn-box-shadow, 0 0 0 $btn-focus-width rgba(mix(color-yiq($petrol), $petrol, 15%), .5));
        } @else {
            // Avoid using mixin so we can pass custom focus shadow properly
            box-shadow: 0 0 0 $btn-focus-width rgba(mix(color-yiq($petrol), $petrol, 15%), .5);
        }
    }
}

.btn-petrol {

    &:hover,
    &:focus {
        background-color: $petrol-light;
        border-color: $petrol-light;
    }
}
