.c-navContent {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    width: 310px;
    height: 100%;
    background: #ffffff;
    transition: all 0.5s;
    transform: translate3d(100%, 0, 0);
    overflow-y: scroll;

    @include media-breakpoint-up(md) {
        width: 410px;
    }

    @include media-breakpoint-up(lg) {
        width: 510px;
    }

    &.show {
        transform: translate3d(0, 0, 0);
    }

    &__header {
        height: 90px;
    }

    &__main {
        background-color: #ffffff;
    }

    &__footer {
        padding-left: calc(1rem + 32px);

        @include media-breakpoint-up(lg) {
            padding-left: calc(1.5rem + 32px);
        }

    }

    &__footerInfo {
        a {
            color: $black;

            &:hover,
            &:focus {
                color: $petrol;
            }
        }
    }

    .navbar-toggler {
        svg path {
            transition: all 0.5s ease-in-out;
        }

        &:hover,
        &:focus {
            svg path {
                fill: $petrol;
            }
        }
    }

    .c-socialMediaNavigation {
        margin-bottom: 4rem;
    }

    &__footerClaim {
        a:hover {
            text-decoration: none;
        }
    }

}
