.c-landingPageMap {

    &__button {
        z-index: 1;

        @include media-breakpoint-up(lg) {
            margin-top: 500px;
        }
    }

    &__wrapper {
        overflow: hidden;
        display: flex;
        align-items: flex-end;
        min-height: 300px;
        padding-bottom: $spacer;

        @include media-breakpoint-up(lg) {
            align-items: center;
            min-height: 1500px;
            padding-bottom: 0;
        }
    }

    &__image {
        position: absolute;
        top: 0;
        left: 0;
        background-image: url("/images/maps/mob_490x268.png");
        background-size: cover;
        width: 100%;
        height: 100%;

        @include media-breakpoint-up(lg) {
            background-image: url("/images/maps/desktop_2560&1920_2750x1501.png");
        }
    }


}
