.c-mapChoice {

    padding-bottom: 0 !important;

    @include media-breakpoint-up(lg) {
        padding-bottom: 2rem !important;
    }

    &__headlineWrapper {
        padding-top: $spacer;

        @include media-breakpoint-up(lg) {
            padding-top: $spacer-xl;
        }
    }

    .card {
        margin-bottom: $spacer;

        @include media-breakpoint-up(lg) {
            margin-bottom: 0;
            height: 100%;
        }

        @include media-breakpoint-up(xl) {
            padding: 4rem;
        }
    }

    .card-body {
        height: 100%;

        @include media-breakpoint-up(lg) {
            display: grid;
        }
    }

    .card-img-top {
        margin-bottom: $spacer;
    }

    .card .btn {
        align-self: flex-end;
    }

}
